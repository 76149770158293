export const ORDER_ID_KEY = 'orderId';

export const ORDER_COOKIE_DURATION = 7;

export const COOKIE_AUTH_EXPIRE_DATE_DAYS = 7;
export const COOKIE_AUTH_KEY = 'tuning-bytes-auth';

export const CART_COOKIE_FORM_KEY = 'tuning-bytes-order-cart';
export const UI_COOKIE_FORM_KEY = 'tuning-bytes-ui';

export const VEHICLE_COOKIE_FORM_KEY = 'tuning-bytes-vehicle';
export const ECU_COOKIE_FORM_KEY = 'tuning-bytes-ecu';

export const BIN_COOKIE_FORM_KEY = 'tuning-bytes-order-bin-file';

export const VEHICLE_COOKIE_KEY = 'vehicle';
export const ECU_COOKIE_KEY = 'ecu';

export const LOCALE_COOKIE_KEY = 'NEXT_LOCALE';

export const CREDIT_COOKIE_KEY = 'credits';
