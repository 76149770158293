'use client';
// SEE: https://alexkorep.com/react/react-many-context-providers-tree/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BuildProviderTree = (providers: any[]): any => {
  if (providers.length === 1) {
    return providers[0];
  }
  const A = providers.shift();
  const B = providers.shift();
  return BuildProviderTree([
    ({ children }: { children: React.ReactNode }) => (
      <A>
        <B>{children}</B>
      </A>
    ),
    ...providers,
  ]);
};
