'use client';
import { AuthProvider } from '@tuning-bytes/users/ui';
import { BuildProviderTree } from '../utils/build-provider-tree.util';
import { HydrationStore } from './hydration-store.provider';

const ProviderList = BuildProviderTree([AuthProvider]);

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProviderList>
      <HydrationStore />
      {children}
    </ProviderList>
  );
};
