import Cookies from 'js-cookie';
import { StateStorage, createJSONStorage } from 'zustand/middleware';

const cookieStorageApi: StateStorage = {
  getItem: function (name: string): string | Promise<string | null> | null {
    const data = Cookies.get(name) || null;
    return data;
  },
  setItem: function (name: string, value: string): void | Promise<void> {
    Cookies.set(name, value);
  },
  removeItem: function (name: string): void | Promise<void> {
    Cookies.remove(name);
  },
};

export const customCookieStorage = createJSONStorage(() => cookieStorageApi);
