'use client';

import { Button } from '@prickle/next-ui';
import { useFormStatus } from 'react-dom';

export const ProviderButton = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode;
}) => {
  const { pending } = useFormStatus();
  return (
    <Button
      outline
      className="inline-flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-medium transition-colors border rounded-md whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground"
      disabled={pending}
    >
      {children}
    </Button>
  );
};
