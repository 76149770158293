'use client';

import { useCartStore } from '@tuning-bytes/cart/data-access';
import { useEffect } from 'react';

export const HydrationStore = () => {
  useEffect(() => {
    useCartStore.persist.rehydrate();
  }, []);

  return <></>;
};
