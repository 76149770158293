import { useTranslations } from 'next-intl';
import { LuCoins } from 'react-icons/lu';

export const CreditBalance = ({ credits }: { credits: number }) => {
  const t = useTranslations('DashboardNav');
  return (
    <div
      className="flex gap-1 items-center tooltip tooltip-info tooltip-bottom hover:tooltip-open"
      data-tip={t('Credits')}
      aria-label={t('Credits')}
    >
      <LuCoins className="w-6 h-6" aria-hidden="true" />
      <span>{credits}</span>
    </div>
  );
};
