import { useTranslations } from 'next-intl';

export const Divider = () => {
  const t = useTranslations('Layout');
  return (
    <div data-orientation="horizontal" role="none" className="divider">
      {t('divider')}
    </div>
  );
};
