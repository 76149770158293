import { create } from 'zustand';

import { ICart } from '../interfaces/cart.interface';

import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ILineProduct } from '@tuning-bytes/products/data-acess';
import { CART_COOKIE_FORM_KEY } from '@tuning-bytes/constants';
import { customCookieStorage } from '@prickle/util-zustand';

type ICartProperties = ICart;

const initialState: ICartProperties = {
  lineItems: [],
};

interface ICartActions {
  addLineItem: (lineItem: ILineProduct) => void;
  addEnginePackageDtcLine: (lineItem: ILineProduct) => void;
  removeEnginePackageDtcLine: (lineItem: ILineProduct) => void;
  removeEnginePackage: (lineItem: ILineProduct) => void;
  setLineItems: (lineItems: ILineProduct[]) => void;
  removeLineItemById: (lineItemId: string) => void;
  removeLineItem: (lineItem: ILineProduct) => void;
  clearCart: () => void;
  getSubTotal: () => number;
  resetStore: () => void;
  getLineProducts: () => ILineProduct[];
}
interface State extends ICartProperties, ICartActions {}

const partializeStore = (state: State) =>
  Object.fromEntries(
    Object.entries(state).filter(([key]) => ![''].includes(key)),
  );

export const useCartStore = create<State>()(
  devtools(
    persist(
      immer((set, get) => ({
        ...initialState,

        addLineItem: (lineItem: ILineProduct) => {
          set(
            (state) => {
              return {
                lineItems: [...state.lineItems, lineItem],
              };
            },
            false,
            'cart/addLineItem',
          );
        },
        addEnginePackageDtcLine: (lineItem: ILineProduct) => {
          set(
            (state) => {
              const hasEngineDtcLine = state.lineItems.some(
                (line) => line.id === lineItem.id,
              );

              if (hasEngineDtcLine) {
                lineItem.price = '0€';
              }

              return {
                lineItems: [...state.lineItems, lineItem],
              };
            },
            false,
            'cart/addEnginePackageDtcLine',
          );
        },
        removeEnginePackageDtcLine: (lineItem: ILineProduct) => {
          const newLineItems = get().lineItems.filter(
            (item) => item.id !== lineItem.id || item.dtc !== lineItem.dtc,
          );

          if (lineItem.price === '0€') {
            return set(
              () => {
                return {
                  lineItems: newLineItems,
                };
              },
              false,
              'cart/removeEnginePackageDtcLine',
            );
          }

          let firstEngineDtcLine = get().lineItems.find(
            (line) => line.id === lineItem.id && line.price === '0€',
          );

          if (firstEngineDtcLine) {
            firstEngineDtcLine = {
              ...firstEngineDtcLine,
              price: lineItem.price,
            };
          }

          return set(
            () => {
              return {
                lineItems: newLineItems.map((line) => {
                  if (
                    line.id === lineItem.id &&
                    line.dtc === firstEngineDtcLine!.dtc
                  ) {
                    return firstEngineDtcLine;
                  }
                  return line;
                }),
              };
            },
            false,
            'cart/removeEnginePackageDtcLine',
          );
        },
        removeEnginePackage: (lineItem: ILineProduct) => {
          const newLineItems = get().lineItems.filter(
            (item) => item.id !== lineItem.id && !item.addon,
          );

          return set(
            () => {
              return {
                lineItems: newLineItems,
              };
            },
            false,
            'cart/removeEnginePackage',
          );
        },
        setLineItems: (lineItems: ILineProduct[]) => {
          set(
            () => {
              return {
                lineItems,
              };
            },
            false,
            'cart/setLineItems',
          );
        },
        removeLineItemById: (lineItemId: string) => {
          set(
            (state) => {
              return {
                lineItems: state.lineItems.filter(
                  (lineItem) => lineItem.id !== lineItemId,
                ),
              };
            },
            false,
            'cart/removeLineItem',
          );
        },
        removeLineItem: (lineItem: ILineProduct) => {
          set(
            (state) => {
              return {
                lineItems: state.lineItems.filter(
                  (item) =>
                    item.id !== lineItem.id || item.dtc !== lineItem.dtc,
                ),
              };
            },
            false,
            'cart/removeLineItem',
          );
        },
        clearCart: () => {
          set(
            () => {
              return {
                lineItems: [],
              };
            },
            false,
            'cart/clearCart',
          );
        },
        getSubTotal: () => {
          return get().lineItems.reduce(
            (acc, item) => acc + +item.price.replace('€', ''),
            0,
          );
        },
        resetStore: () => {
          set(
            () => {
              return {
                ...initialState,
              };
            },
            false,
            'cart/resetStore',
          );
        },
        getLineProducts: () => {
          return get().lineItems;
        },
      })),
      {
        name: CART_COOKIE_FORM_KEY,
        partialize: partializeStore,
        storage: customCookieStorage,
        skipHydration: true,
      },
    ),
    { name: CART_COOKIE_FORM_KEY },
  ),
);
