'use client';
import { Menu, Transition } from '@headlessui/react';

import { cn } from '@prickle/ui';

import React, { Fragment } from 'react';

import { Link } from '@prickle/next-ui';
import { IconCarGarage } from '@tabler/icons-react';

import { SignOutButton } from '@tuning-bytes/users/ui';
import { Session } from 'next-auth';
import { useTranslations } from 'next-intl';

export default function LayoutAuthenticatedMenu({
  session,
}: {
  session: Session;
}) {
  const t = useTranslations('Navbar');
  return (
    <>
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="relative flex text-sm bg-white rounded-full focus:outline-none ring-2 focus:ring-indigo-500 ring-offset-2">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">{t('menu-sr')}</span>
            <IconCarGarage size={32} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right rounded-md shadow-lg bg-neutral text-neutral-content ring-1 ring-neutral ring-opacity-5 focus:outline-none">
            <ul className="menu menu-vertical">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href="/dashboard/profile"
                    as="next-link"
                    hover
                    className={cn(
                      active ? 'active' : '',
                      'block px-4 py-2 text-md',
                    )}
                  >
                    {t('Account')}
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <SignOutButton
                    className={cn(
                      active ? 'active' : '',
                      'block px-4 py-2 text-md',
                    )}
                  >
                    {t('Logout')}
                  </SignOutButton>
                )}
              </Menu.Item>
            </ul>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
