'use client';
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { IconUserCircle } from '@tabler/icons-react';
import { cn } from '@prickle/ui';
import { NewUserButton, SignInButton } from '@tuning-bytes/users/ui';
import { useTranslations } from 'next-intl';

export default function LayoutUnauthenticatedMenu() {
  const t = useTranslations('Navbar');
  return (
    <>
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="relative flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="absolute -inset-1.5" id="user-menu" />
            <span className="sr-only">{t('menu-sr')}</span>
            <IconUserCircle
              className="text-gray-400 w-7 h-7"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right rounded-md shadow-lg bg-neutral text-neutral-content ring-1 ring-neutral ring-opacity-5 focus:outline-none menu menu-vertical">
            <Menu.Item>
              {({ active }) => (
                <SignInButton
                  className={cn(
                    active ? 'active' : '',
                    'block px-4 py-2 text-md',
                  )}
                >
                  {t('Login')}
                </SignInButton>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NewUserButton
                  className={cn(
                    active ? 'active' : '',
                    'block px-4 py-2 text-md',
                  )}
                >
                  {t('Register')}
                </NewUserButton>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
