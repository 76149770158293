'use client';

import { cn } from '@prickle/ui';
import {} from 'next-auth/react';
import { useRouter } from 'next/navigation';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export function NewUserButton({ className, children }: Props) {
  const classes = cn('link', 'link-hover', className);
  const router = useRouter();

  const createUser = async () => {
    router.push('/auth/register');
  };
  return (
    <li className={classes} onClick={() => createUser()} role="menuitem">
      {children || 'Sign Up'}
    </li>
  );
}
