import { ServiceNav } from '@tuning-bytes/dto';

export const SEVICES_NAVIGATION: ServiceNav[] = [
  {
    name: 'Stage 1',
    href: '/order/stage1',
    imageSrc: '/assets/stage-1-reduced.webp',
    imageAlt: 'stage 1',
  },
  {
    name: 'Stage 2',
    href: '/order/stage2',
    imageSrc: '/assets/stage-2-reduced.webp',
    imageAlt: 'stage 2',
  },

  {
    name: 'Solutions',
    href: '/order/solutions',
    imageSrc: '/assets/solutions-reduced.webp',
    imageAlt: 'solutions',
  },
  {
    name: 'Gearbox',
    href: '/order/gearbox',
    imageSrc: '/assets/gearbox-reduced.webp',
    imageAlt: 'gearbox',
  },
];

export const BUSINESS_STEPS_IMAGES = [
  '/assets/login-signup.jpg',
  '/assets/pay-credits.jpg',
  '/assets/get-tune-2.jpg',
];
