'use client';

import { cn } from '@prickle/ui';
import { signOut } from 'next-auth/react';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export function SignOutButton({ className, children }: Props) {
  const classes = cn('link', 'link-hover', className);
  return (
    <li className={classes} onClick={() => signOut()} role="menuitem">
      {children || 'Sign out'}
    </li>
  );
}
