import { Select } from '@prickle/next-ui';
import { locales } from '@tuning-bytes/constants';
import { useTranslations } from 'next-intl';

export const LanguageSelection = ({ locale }: { locale: string }) => {
  const t = useTranslations('LanguageSelection');

  return (
    <Select
      name="preferred_language"
      label={{
        text: t('form.preferred_language'),
      }}
      defaultValue={locale}
    >
      {locales.map((availableLocale) => (
        <Select.Option
          key={availableLocale}
          value={availableLocale}
          label={t(availableLocale)}
        />
      ))}
    </Select>
  );
};
