'use client';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export const ErrorMessage = ({
  errorMessage,
}: {
  errorMessage: string | undefined;
}) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <div
      className="flex items-end h-6 space-x-1"
      aria-live="polite"
      aria-atomic="true"
    >
      <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
      <p className="text-sm text-red-500">{errorMessage}</p>
    </div>
  );
};
