'use client';
import { useFormState } from 'react-dom';
import { redirectBack } from '../actions/redirect-back-action';
import { BackButton } from '@tuning-bytes/ui';
import { useTranslations } from 'next-intl';

export const RedirectBack = () => {
  const t = useTranslations('Layout.actions');
  const [, dispatch] = useFormState(redirectBack, undefined);

  return (
    <form action={dispatch}>
      <BackButton position="absolute">{t('back')}</BackButton>
    </form>
  );
};
